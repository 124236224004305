.cardHead
{
    background-color: white !important;
}

.cardHeight
{
    height: 180px;
}
.cardContent
{
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp: 2;
}
.cardRightSide1
{
    height: 450px !important;
    width: 1090px;
    overflow-x: hidden !important;
}
.acCard
{
    width: 100%;
    align-content: space-around;
    display: flex;
}
.sidecard
{
        background: linear-gradient(to bottom, #dcdeef 13%, rgba(85, 110, 230, 0.25) 85%);
        border-radius: 10px;
}
.head{
    text-align: center;
}
.extra-space {
    margin-top: 10px;
    margin-left: 20px;
}

.clicked-card {
    color: red; /* Color for clicked card */
  }
  
  .hovered-card {
    color: red; /* Color for hovered card */
  }
  
.extra{
  
    margin-left: 23px;
}

.line3 {
    border-top:0.5px solid rgb(204, 199, 199);
    transform: scaleX(0.98); 
}
.line2 {
    border-top: 1px solid blue; 
    transform: scaleX(0.98); 
}
.rightalign {
    margin-top: 0px;
}
.iconspace{
    margin-right: 5px;
   
}

.paddingrightleft{
    padding: 16px;
}
.markss
{
    margin-left: 25px;
}
.durations{
    margin-left: 18px;
}
.scores{
    margin-left: 43px;
}
.cardnumber1 {
    font-size: 11px !important;
    // margin-right: -22px !important
}
.cardnumber {
    font-size: 11px !important;
}

.cardSizeSub {
    //    / padding: 8px;
    height: 165px;
    // width: 300px;
    margin: 8px;
    width: 31.75% !important;
}

.cardSizeSub1 {
    //    / padding: 8px;
    height: 165px;
    border-style: "solid hidden hidden hidden" !important;
    border-color: green !important;
    // width: 300px;
    margin: 8px;
    width: 31.75% !important;
}
.cardSizeSub:hover{
    cursor: pointer !important;
    background-color: rgb(212, 239, 245) !important
}

.WholeCard{
    padding: 8px;
}
.buttong{
    text-align: center;
}
.buttong:hover{
    color: white !important;

}
.buttongg:hover{
    color: white !important;

}

.cardRow{
    margin: 8px !important;
}
.scorecolval{
    font-size: 12px !important;
    margin-right: -83px !important;
    // margin-left: -17px !important;
}
.quescolval{
    font-size: 12px !important;
    margin-right: -73px !important;
    // margin-left: -11px !important;
}
.durationcolval{
    font-size: 11px !important;
    margin-right: -73px !important;
    // margin-left: -11px !important;
}
.buttong1{
    text-align: center !important;
}
.scorealign{
    margin: 3px;
}
.quesalign{
    margin: 3px;
}
.duralign{
    margin: 3px;
}

.smgroup{
    font-size: 13px !important;
    --bs-text-opacity: 1;
    color: gray !important;
}

.mainpage{
    max-height: 70vh !important;
    overflow-y: scroll !important;
    overflow-x: hidden;
}
.smbadge{
    color: #000 !important;
    background-color: #fff !important;
}

.subject > .subject-li > div > a:hover {
    color: #000!important;
    background: rgba(0, 0, 0, .05);
  }
  
  .subject > .subject-active > div > a {
    color: #fff!important;
    background: #f46a6ac2;
  }

  .subject > .subject-li > div > a:hover > .smgroup {
    font-size: 13px !important;
    --bs-text-opacity: 1;
    color: #000 !important;
  }
  
  .subject > .subject-active > div > a > .smgroup {
    font-size: 13px !important;
    --bs-text-opacity: 1;
    color: #fff !important;
  }


  .imgalignn1o{
    text-align:center;
    background: white !important;
}

.reviebutton{
    // margin-top: -35px !important;
    // margin-left: 204px !important;
    // margin-top: -25px;
    // margin-left: -60px;
}