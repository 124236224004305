.registerscreencontainer {
    max-width: 1190px !important;
}

.form-check {
    text-align: right;
    padding-right: 1.5em;
    padding-left: 0;
    margin-left: 88px !important;
    margin-top: -20px !important;

    .form-check-input {
        float: right;
        margin-right: -1.5em;
    }
}

.togglealign{
    margin-left: 50px !important;
}
.whatsappicon{
    margin-left: 80px;
    font-size: 16px !important;
}
.togglealign1{
    margin-left: 113% !important;
}
.whatsappicon1{
    margin-left: 220px !important;
    font-size: 16px !important;
}
.whatsapp{
    margin-left: 25px !important;
}
.bg-registerradiant
{
  background: radial-gradient(592px at 48.2% 50%, rgba(255, 255, 255, 0.6) 0%, rgb(203, 225, 255) 74.6%)
}
.Rgisterscreen{
    margin-top: -45px !important;
}

.signupcard{
    margin: 16px;
}
.registerheadnavsize{
    font-size: 11px !important;
}
.carousel-text {
    color: white; 
    // font-weight: bold; 
  }


