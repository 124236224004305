.colgap{
    column-gap: 10px;
}
.footercss{
    bottom: 0;
    padding: 10px calc(24px* 0.75);
    right: 0;
    color: var(--bs-footer-color);
    left: 250px;
    height: 45px;
    margin-left: -247px;
    background-color: var(--bs-footer-bg);
    position: fixed;
}
.cursor-focus{
    cursor: pointer !important;
  }
  .btnModalDesign {
    column-gap: 1rem !important;
    display: flex;
  }
  .btnModalDesign1 {
    /* column-gap: 1rem !important; */
    /* display: flex; */
    justify-content: center !important;
  }