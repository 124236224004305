.paddingspace{
    margin-left: 20px;
}
.pref{
    width: max-content !important;
    overflow: auto;
}
.carousel-text {
    overflow: hidden;
    // text-overflow: ellipsis;
    white-space: nowrap;
  }