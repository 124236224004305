.cardHead {
    background-color: white !important;
}

.cardHeight {
    height: 180px;
}

.cardContent {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.cardRightSidelength {
    height: 700px;
    position: scroll;
}
.reviebutton2{
    /* margin-top: -25px; */
    margin-left: -35px !important;
}
.acCard {
    width: 100%;
    align-content: space-around;
    display: flex;
}

.sidecard {
    background: linear-gradient(to bottom, #dcdeef 13%, rgba(85, 110, 230, 0.25) 85%);
    border-radius: 10px;
}

.head {
    text-align: center;
}

.extra-space {
    margin-top: 20px;
    margin-left: 20px;
}

.extra {

    margin-left: 23px;
}

.rightalign1 {
    margin-top: 28px;
}

.iconspace {
    margin-right: 5px;
}

.cardSizefl {
        height: 164px;
        margin: 8px;
        width: 23.66% !important;
    }
.paddingspace{
    margin-left: 20px;
}
.required {
    color: red;
  }
