.cardHead
{
    background-color: white !important;
}

.cardHeight
{
    height: 180px;
}
.cardContent
{
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp: 2;
}
.cardRightSide1
{
    height: 450px !important;
    overflow: scroll;
}
.acCard
{
    width: 100%;
    align-content: space-around;
    display: flex;
}
.sidecard
{
        background: linear-gradient(to bottom, #dcdeef 13%, rgba(85, 110, 230, 0.25) 85%);
        border-radius: 10px;
}
.head{
    text-align: center;
}
.extra-space {
    margin-top: 10px;
    margin-left: 20px;
}
.page-title-box22{
    padding-top: 14px !important;
    padding-bottom: 14px !important;
}
.imgalignno{
        // align-self:center;
        background: white !important;
    text-align:center;
}

// .nav-pills .nav-link.active,
// .nav-pills .show > .nav-link {
//     color: #f46a6a !important;
//     background-color:transparent !important;
// }

// /* Override the hover color to red */
// .nav-pills .nav-link:hover {
//     color: #f46a6a !important;
//     background-color: transparent !important; 
// }

.custom-accordion > a:hover {
    color: red;
  }
  
.mainpage1{
    max-height: 70vh !important;
    overflow-y: scroll !important;
    overflow-x: hidden;
}
.reviebutton1{
    margin-top: -11px;
    margin-left: -46px;
}
.revbutt{
    height: 33px !important;
    width: 40px !important;
}
.prec{
    padding: -4px !important;
}
.extra{
  
    margin-left: 23px;
}

.line1 {
    border-top: 1px solid green;
    transform: scaleX(0.98); 
}
.line2 {
    border-top: 1px solid blue; 
    transform: scaleX(0.98); 
}
.rightalign {
    margin-top: 0px;
}
.iconspace{
    margin-right: 5px;
   
}


.paddingrightleft{
    padding: 16px;
}
.markss
{
    margin-left: 25px;
}
.durations{
    margin-left: 18px;
}
.scores{
    margin-left: 43px;
    color: black;
    font-weight: bold;
}
.cardnumber {
    font-size: 18px;
}

.cardSize {
    height: 163px;
    margin: 8px;
    width: 31.75% !important;
}
.WholeCard{
    padding: 8px;
}

.cardRow{
    margin: 8px !important;
}

.cardSizeSub {
    //    / padding: 8px;
    height: 165px;
    // width: 300px;
    margin: 8px;
    width: 31.75% !important;
}

.subject > .subject-li > a:hover {
    color: #000!important;
    background: rgba(0, 0, 0, .05);
  }
  
  .subject > .subject-active > a {
    color: #fff!important;
    background: #f46a6a;
  }