@import url('https://fonts.googleapis.com/css2?family=Anek+Malayalam:wght@100;200;300;400;500;600;700;800&display=swap');

/* body {
  font-family: 'Anek Malayalam', sans-serif;
  background: linear-gradient(90.04deg, #556ee6 0.03%, #ffc0cb 99.96%);
  color: #11052c;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0 30px;
} */

.quiz-container {
  /* max-width: 700px;
  min-width: 250px; */
  background: #ffffff;
  border-radius: 4px;
  /* margin-top: 100px; */
  /* padding: 30px 60px; */
}

.bg-btn1 {
  color: darkred !important;
}

.custom-badge.btn.btn-danger {
  /* Your custom styles here */
  background-color: red !important;
  color: white !important;
}

.custom-badge.btn.btn-success {
  /* Your custom styles here */
  background-color: green !important;
  color: white !important;
}

.custom-badge.btn.btn-blue {
  /* Your custom styles here */
  background-color: blue !important;
  color: white !important;
}
.custom-badge.btn.btn-lightblue {
  /* Your custom styles here */
  background-color: #f0f2fd66 !important;
  color: #000 !important;
}
.custom-badge.btn.btn-purple {
  /* Your custom styles here */
  background-color: purple !important;
  color: white !important;
}

.custom-badge.btn.btn-yellow {
  /* Your custom styles here */
  background-color: orange !important;
  color: white !important;
}

.custom-badge.btn.btn-magenta {
  /* Your custom styles here */
  background-color: magenta !important;
  color: white !important;
}


/* .my-custom-card .card-body {
  padding: 0px !important;
  height: 10px;
}

/* .card-body {

  padding: 2px !important;

} */
.bg-radiant {
  background: radial-gradient(592px at 48.2% 50%, rgba(255, 255, 249, 0.6) 0%, rgb(160, 199, 254) 74.6%)
}

.page-content {
  padding: calc(7px + 24px) calc(24px* 0.75) 60px calc(24px* 0.75) !important;
}

.custom-badge.btn.btn-silk {
  margin-right: 20px;
  background: #556ee6;
  border-radius: 9px;
  font-size: 12px;
  color: #ffffff;
  padding: 10px 12px;
  outline: none;
  border: none;
  cursor: pointer;
  margin-top: 15px;
}

/* .quiz-container .active-question-no {
  font-size: 28px;
  font-weight: 500;
  color: #556ee6;
}

.quiz-container .total-question {
  font-size: 16px;
  font-weight: 500;
  color: #e0dee3;
} */

.quiz-container h2 {
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
}

.quiz-container ul {
  margin-top: 20px;
  margin-left: -40px;
}

.quiz-container ul li {
  text-decoration: none;
  list-style: none;
  color: #2d264b;
  font-size: 14px;
  background: #ffffff;
  border: 1px solid #eaeaea;
  border-radius: 16px;
  padding: 11px;
  margin-top: 15px;
  margin-left: 20px;
  cursor: pointer;
}

/* li:hover {
  background-color: lightgreen;
} */

.quiz-container ul .selected-answer {
  background: #556ee617;
  border: 1px solid #58e655;
}
.quiz-container ul .selected-review-answer {
  background: #90ee905c;
  border: 1px solid #58e655;
}

.quiz-container ul .wrong-answer {
  background: #ff00001c;
  border: 1px solid red;
}
.quiz-container button {
  background: #556ee6;
  border-radius: 9px;
  font-size: 12px;
  color: #ffffff;
  padding: 10px 12px;
  outline: none;
  border: none;
  cursor: pointer;
  /* margin-top: 15px; */
}


.quiz-container button:disabled {
  background: #e7e8e9;
  color: #9fa3a9;
  cursor: not-allowed;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.result h3 {
  font-size: 24px;
  letter-spacing: 1.4px;
  text-align: center;
}

.result p {
  font-size: 16px;
  font-weight: 500;
}

.result p span {
  color: #556ee6;
  font-size: 22px;
}

body {
  margin: 0;
  color: #444;
  background: #ecf0f1;
  font: 300 18px/18px Roboto, sans-serif;
}

*,
:after,
:before {
  box-sizing: border-box
}

.pull-left {
  float: left
}

.pull-right {
  float: right
}

.clearfix:after,
.clearfix:before {
  content: '';
  display: table
}

.clearfix:after {
  clear: both;
  display: block
}

.clock:before,
.count:after {
  content: '';
  position: absolute;
}

.clock-wrap {

  width: 200%;

  margin-top: 65px;
  position: relative;
  border-radius: 50px;
  /* background-color: #fff; */
}

.clock {

  left: 50%;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  position: absolute;
  margin-top: -80px;
  margin-left: 40px;
  /* background-color: #feeff4; */
}

.clock:before {
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin-top: -30px;
  margin-left: -29px;
  border-radius: inherit;
  /* background-color: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, .15), 0 0 3px rgba(200, 200, 200, .75) inset; */

}

.rounded-pill {
  margin-left: 15px;
  padding-right: .6em;
  padding-left: .6em;
}

.count {
  /* width: 100%; */
  color: black;
  /* height: 100%; */
  padding: 20px 17px;
  font-size: 14px;
  font-weight: 500;
  line-height: 40px;
  position: absolute;
  text-align: center;
}

.count1{
  margin-left: -69px !important;
  cursor: pointer;
}

.active-question-no {
  font-size: 38px;
  font-weight: 500;
  color: #556ee6;
}

.total-question {
  font-size: 16px;
  font-weight: 500;
  color: #e0dee3;
}

.count:after {
  width: 100%;
  display: block;
  font-size: 18px;
  font-weight: 300;
  line-height: 18px;
  text-align: center;
  position: relative;
}

.count.sec:after {
  content: 'sec'
}

.count.min:after {
  content: 'min'
}

.action {
  margin: auto;
  max-width: 200px;
}

.action .input {
  margin-top: 30px;
  position: relative;
}

.action .input-num {
  width: 100%;
  border: none;
  padding: 12px;
  border-radius: 60px;
}

.action .input-btn {
  top: 0;
  right: 0;
  color: #fff;
  border: none;
  border: none;
  padding: 12px;
  position: absolute;
  border-radius: 60px;
  background-color: #ec366b;
  text-transform: uppercase;
}

.tbl {
  display: table;
  width: 100%
}

.tbl .col {
  display: table-cell
}

.activeques {
  /* padding-left: 180px; */
}



.cardContent {
  margin-top: 88px;
  margin-left: 16px;
  margin-right: -7px;
  background-color: white !important;
}

.topicContent {
  padding-left: 100px !important;
}

/* .t */
.headingpad {
  padding-left: 16px !important;
}

.PieCard {
  background-color: #e3eeff !important;
  margin-left: 16px;
  margin-top: 16px;
}

.performenceCard {
  background-color: #ffffff !important;
  margin-right: 16px !important;
  margin-top: 16px !important;
}

.analyticCard {
  background-color: #1df5c6 !important;
  margin-left: 16px;
}

.accorhead {
  display: inline-block;
  width: 63%;
}

.accorhead1 {
  display: inline-block;
  width: 515px;
}

.accordprogress {
  width: 30%;
}

.accordprogress1 {
  width: 30%;
  margin-left: 446px !important;
  /* align-items: right !important; */
}

.wholeaccord {
  width: 405px;
  display: contents;
}

.accordsize {
  display: inline-block;
  width: 73%;
}

.iconSizesummary {
  font-size: 25px !important;
}

.heading {
  padding-left: 16px !important;
  padding-top: 16px !important;
}

.accordval {
  width: 100%;
}

.progressBarinternal {
  display: inline-flex !important;
  width: 30% !important;
}

/* .prog{
  width: 33% !important;
} */
.prog {
  position: absolute;
  bottom: 0;
  width: 88%;
}

.widget {
  margin-left: 16px;
  margin-top: 16px;
}

.reviewbtn {
  float: right;
  margin-right: 20px;
}

.accordion>.accordion-item>.accordion-header>.accordion-button {
  padding-bottom: 0px !important;
  padding-top: 0.5rem !important;
}

.markbadgesize {
  font-size: 14px !important;
  font-weight: bold !important;
}

.clrresponse {
  margin-right: 35%;
  /* position: fixed; */
}
.align123{
  /* margin-left: -42px; */
    font-size: 32px;
   
}

.Progressbar {
  /* max-height: 10px !important;
  max-width: 10px !important; */
  /* margin-top: 12px;
  margin-left: -50px; */
  /* position: absolute; */
  top: -7px !important;
  left: -65px !important;
  z-index: 9;
}
#pomodoro-timer svg  {
  height: 68px !important;
  /* margin-bottom: 20px; */
}

#pomodoro-timer > div  {
  height: 68px !important;
  /* margin-bottom: 20px; */
}
.timer {
  font-size: 14px;
  font-weight: 500;
}
.cls-div-qn
{
  min-height: 400px;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.li-option:hover{
  background-color: lightgreen !important;
}