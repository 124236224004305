.main {
  text-align: center;
  font-family: "Times New Roman";
}

.marq {
  padding-top: 30px;
  height: 450px;
  padding-bottom: 30px;
}

.geek1 {
  padding-top: 10px;
  font-size: 14px;
  font-weight: bold;
  color: black;
  text-align: center;
}

.geek2 {
  text-align: center;
}

.landing-card {
  max-width: 100%;
  max-height: 600px !important;
}

.newsCard {
  background-color: #e7f1f5;
}

.news {
  // box-shadow: inset 0 -15px 30px rgba(0,0,0,0.4), 0 5px 10px rgba(0,0,0,0.5);
  width: 350px;
  height: 30px;
  // margin: 20px auto;
  overflow: hidden;
  border-radius: 4px;
  padding: 16px;
  -webkit-user-select: none
}

.full-width {
  width: 100%;
}
.iframeSize{
  margin-left: 85px;
}
.news span {
  float: left;
  color: #fff;
  padding: 6px;
  position: relative;
  top: 1%;
  border-radius: 4px;
  // box-shadow: inset 0 -15px 30px rgba(0,0,0,0.4);
  font: 16px 'Source Sans Pro', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-user-select: none;
  cursor: pointer
}

.news ul {
  float: left;
  padding: 4px;
  // animation: ticker 6s cubic-bezier(1, 0, .5, 0) infinite;
  -webkit-user-select: none
}

.news ul li {
  line-height: 30px;
  list-style: none
}

.news ul li a {
  color: #fff;
  text-decoration: none;
  font: 14px Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-user-select: none
}

//   @keyframes ticker {
// 	0%   {margin-top: 0}
// 	25%  {margin-top: -50px}
// 	50%  {margin-top: -100px}
// 	75%  {margin-top: -125px}
// 	100% {margin-top: -90px}
// }
.card-Marquee {
  // padding-right: 17px;
  border-radius: 10px !important;
  margin-bottom: 8px !important;
  padding: 8px !important;
  height: 150px !important;
  background-color: white;
  box-shadow: 1px 1px 1px rgb(169, 176, 175);
}
.card-Marquee:hover {
  animation-play-state: paused !important;

  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0,0,0,0.11),
    0 24px 46px var(--box-shadow-color);
}

.marq {
  background-color: 'red' !important;
}

.news span:hover+ul {
  animation-play-state: paused
}

.red {
  height: fit-content;
  background: #b6c2ff
}


.align {
  text-align: left;
}

.marginleftname{
 margin-left: 32%
}


.preferenceoverflow{
  max-height: 60% !important;
  overflow-y: scroll;
}
// @keyframes marqueeTop {
//   0% {
//     top: 0;
//   }

//   100% {
//     top: -50%;
//   }
// }

// @keyframes marqueeBottom {
//   0% {
//     top: -50%;
//   }

//   100% {
//     top: 0;
//   }
// }

.videoCarousel > .carousel .carousel-control-prev {
  left: -28px;
}
.videoCarousel > .carousel .carousel-control-next {
  right: -28px;
}
.videoCarousel > .carousel .carousel-indicators {
  margin-bottom: -12px;
}
.buttonalignment{
  padding-right: 20px;
}
.buttalign{
  text-align: center;
}
