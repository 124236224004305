.cardHead
{
    background-color: white !important;
}

.cardHeight
{
    height: 180px;
}
.cardContent
{
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient:vertical;
    -webkit-line-clamp: 2;
}
.cardRightSide
{
    height: 480px;
    position: sticky;
}
.acCard
{
    width: 100%;
    align-content: space-around;
    display: flex;
}
.sidecard
{
        background: linear-gradient(to bottom, #dcdeef 13%, rgba(85, 110, 230, 0.25) 85%);
        border-radius: 10px;
}
.head{
    text-align: center;
}
.extras-space {
   
    margin-left: 50px;
}
/* style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} */
.firstrow{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.extras{
  
    margin-left: 40px;
}
.headingspace{
  
    margin-left: 10px;
}

.rightalign1{
  margin-top: 28px;
}
.btnspace{
    margin-right: 15px;
}

.cardtopalign{
    margin-top: 5px;
}

.cardtopalign1{
    margin-top: 10px;
}

.leftalignspa{
    margin-left: 5px;
}