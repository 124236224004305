.smgroup{
    font-size: 13px !important;
    --bs-text-opacity: 1;
    color: gray !important;
}
.mainpage{
    max-height: 70vh !important;
    overflow-y: scroll !important;
    overflow-x: hidden;
}
.smbadge{
    color: #000 !important;
    background-color: #fff !important;
}
.sub_li {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
}
.subbadge{
    border-radius: 50%;
    width: 25px;
    height: 25px;
    padding: 4px;
    background: #fff;
    text-align: center;
}
/* .subject:active{
    color: white !important;
    background-color: #f46a6a !important;
}
.subject:hover{
    color: white !important;
    background-color: grey !important;
} */

.subject > .subject-li > a:hover {
  color: #000!important;
  background: rgba(0, 0, 0, .05);
}

.subject > .subject-active > a {
  color: #fff!important;
  background: #f46a6a;
}
.whoe{
    position: relative;
}

.imgalignno{
    align-self:center;
}
.pdfcolor{
    color: #fc3441
}

.imagecolor{
    color: #34c38f
}
.text-black {
    --bs-text-opacity: 1;
    color: #000 !important;
}

.referencecard {
    max-height: 50vh;
    overflow: auto;
}

/* scrollbar */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255, 255, 255, 0.3);
}

.referencehead{
    position: fixed;
    background: white;
    z-index: 999;
}
.othertopics{
    padding: 4px 12px;
    color: #74788d;
    font-size: 13px;
    font-weight: 400;
}

.badgebackground{
    background-color: rgb(240, 240, 240) !important;
}

.iframeSize1{
    margin-left: 0px !important;
  }
.cr-icon-button{
    display: none !important;
}
